import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import ButtonLink from "../Button/ButtonLink";
import LogoUri from "../public/Logo.svg";
import translations from "../lang/translations";
import en from "../lang/en";
import { FormattedMessage } from 'react-intl'

export type HeaderProps = {
  isMenuOpen: boolean;
  onMenuToggle: React.DOMAttributes<
    HTMLButtonElement | HTMLAnchorElement
  >["onClick"];
};

export default function Header({ isMenuOpen, onMenuToggle }: HeaderProps) {
  return (
    <header className="Header">
      <div className="Header_content">
        <nav className="Header_navigation">
          <a href="/">
            <img
              src={LogoUri}
              alt="LiveSupp"
              className="Header_navigation_logo"
            />
          </a>
          <div className="Header_aside">
            <button
              className={clsx(
                "Header_burger",
                isMenuOpen && "Header_burger__active"
              )}
              onClick={onMenuToggle}
            >
              ☰
            </button>
            <div
              className={clsx("Header_menu", isMenuOpen && "Header_menu__open")}
            >
              <div className="Header_menu_content">
                <Link
                  onClick={onMenuToggle}
                  to=""
                  className="Header_menu_link"
                >
                  {translations.header_home}
                </Link>
                
                <FormattedMessage id="header_usecase_link" defaultMessage={en.header_usecase_link}>
                  {
                    (msg) =>  {
                      const [href] = msg
                      return (
                    <a
                      onClick={onMenuToggle}
                      href={href as unknown as string}
                      className="Header_menu_link"
                    >
                      {translations.header_usecase}
                    </a>)}
                  }
                </FormattedMessage>
                <FormattedMessage id="header_training_link" defaultMessage={en.header_training_link}>
                  {
                    (msg) =>  {
                      const [href] = msg
                      return (
                    <a
                      onClick={onMenuToggle}
                      href={href as unknown as string}
                      className="Header_menu_link"
                    >
                      {translations.header_training}
                    </a>)}
                  }
                </FormattedMessage>
                <FormattedMessage id="header_features_link" defaultMessage={en.header_features_link}>
                  {
                    (msg) =>  {
                      const [href] = msg
                      return (
                    <a
                      onClick={onMenuToggle}
                      href={href as unknown as string}
                      className="Header_menu_link"
                    >
                      {translations.header_features}
                    </a>)}
                  }
                </FormattedMessage>
                <FormattedMessage id="header_contact_link" defaultMessage={en.header_contact_link}>
                  {
                    (msg) =>  {
                      const [href] = msg
                      return (
                    <a
                      onClick={onMenuToggle}
                      href={href as unknown as string}
                      className="Header_menu_link"
                    >
                      {translations.header_contact}
                    </a>)}
                  }
                </FormattedMessage>
                <FormattedMessage id="header_lang" defaultMessage={en.header_lang}>
                  {
                    (msg) =>  {
                      const [href] = msg
                      return (
                    <a
                      onClick={onMenuToggle}
                      href={href as unknown as string}
                      className="Header_menu_lang"
                    >
                      {translations.header_lang_desc}
                    </a>)}
                  }
                </FormattedMessage>
                <ButtonLink
                  type="secondary"
                  href="/administration/login"
                  className="Header_menu_button Header_menu_button_color"
                >
                  {translations.header_signin}
                </ButtonLink>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
