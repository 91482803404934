const cs = {
    header_home: "Úvod",
    header_usecase: "Způsoby využití",
    header_usecase_link: "/cs/use-case.html",
    header_training: "Poradenství a školení",
    header_training_link: "/cs/training.html",
    header_features: "Funkce",
    header_features_link: "/cs/features.html",
    header_contact: "Kontakt",
    header_contact_link: "/cs/contact.html",
    header_lang: "/",
    header_lang_desc: "En",
    header_signin: "Přihlášení",
    header_signin_link: "/administration/login",
    toplook_column_heading: "S námi vyřešíte cokoli na dálku.",
    toplook_column_body: "LiveSupp je komunikační platforma k odbavení zákazníků, partnerů či zaměstnanců na dálku po příchodu na webové stránky nebo klikem na poslaný odkaz.",
    toplook_column_cta: "Ukázka a pilot zdarma",
    percentagecircle1: "Více konverzí z webu a volání.",
    percentagecircle2: "Efektivnější než text chaty a chatboti.",
    percentagecircle3: "Zvýšení hodnoty objednávek.",
    percentagecircle4: "Zefektivnění práce zaměstnanců.",
    percentagecircle5: "Urychlení obchodních procesů.",
    box_1: "Zvýšení konverzí z webových stránek, telefonní komunikace a reklamních kampaní.",
    box_2: "Zvýšení efektivity práce, kontrola v reálném čase.",
    box_3: "Zvýšení spokojenosti klientů, budování důvěry a pozitivního vnímání společnosti.",
    communication_heading: "Unikátní obchodní nástroj",
    communication_subheading: "LiveSupp spojuje výhody osobní komunikace (flexibilita, důvěra a budování vztahu) a vzdáleného odbavení (rychlost, efektivita a analýza dat). Vyniká především komplexností všech potřebných funkcí na jednom místě a jednoduchostí jejich použití:",
    communication_li_1: "Hlasové a video hovory, textový chat, GDPR ready.",
    communication_li_2: "Sdílení obrazovky a kamery, společné ovládání webu a dokumentů.",
    communication_li_3: "Elektronický podpis dokumentů splňující EU směrnici eIDAS.",
    communication_li_4: "Asistence v reálném čase s kontrolou nad dokončením konverze.",
    communication_li_5: "Interaktivní vizualizace s pořízením multimediálního záznamu.",
    communication_cta: "Všechny funkce",
    effectivity_heading: "Maximální efektivita",
    effectivity_li_1: "Jedním klikem bez instalace, registrace a přihlašování.",
    effectivity_li_2: "Vizualizace přímo displeji telefonu během telefonního hovoru.",
    effectivity_li_3: "Vhodné pro home office a efektivní využívání času specialistů.",
    happiness_heading: "Možnosti využití",
    happiness_li_1: "Asistovaný prodej, zákaznická péče a zjišťování zpětné vazby.",
    happiness_li_2: "Reklamní kampaně – pro zvýšení efektivity prodeje a odlišení od konkurence",
    happiness_li_3: "Procesní podpora v partnerských kanálech a obchodních sítích.",
    happiness_li_4: "Technická podpora se sdílením obrazovky či kamery.",
    happiness_li_5: "Reklamační řízení s pořízením záznamu události a podpisem reklamačního protokolu.",
    happiness_li_6: "HR – nábor zaměstnanců, kontrola v reálném čase, podpis smluv a předávacích protokolů.",
    happiness_cta: "Další možnosti využití",
    statistics_heading: "Vše pod kontrolou",
    statistics_li_1: "Přehled o výkonu zaměstnanců v reálném čase.",
    statistics_li_2: "Historie akcí, archivace nahrávek a hodnocení po spojení.",
    statistics_li_3: "Statistiky a analýza výsledků pro rozvoj obchodních procesů.",
    box_4: "Jednoduchá instalace vložením skriptu do zdrojového kódu webových stránek.",
    box_5: "Využíváme WebRTC protokoly prohlížečů s bezpečným peer-to-peer spojením.",
    box_6: "Spojení jedním klikem na webu nebo na zaslaný odkaz, bez instalace a registrace.",
    references_heading: "Naši klienti",
    references_1: `"Možnost chatu v našich online kalkulačkách chyběla a rozhodli jsme se, že si tento nástroj nebudeme programovat sami, ale využijeme LiveSupp. Ten nám navíc umožnil i rozšířit další místa pro získání kontaktu s klientem – chytrý callback. S LiveSuppem jsme spokojeni z hlediska navýšení počtu obchodních příležitostí, ale také i z pohledu zlepšení poskytovaných služeb našim klientům."`,
    references_2: `"Při porovnání služby LiveSupp s našimi předchozími nástroji (chat a chatbot), překonaly výsledky všechna naše očekávání. Okamžitě se zvedl počet objednávek z našich webových stránek a LiveSupp se stal jedním z našich významných prodejních kanálů."`,
    pricing_heading: "Chcete si LiveSupp zdarma vyzkoušet?",
    pricing_user_heading: `Uživatel`,
    pricing_user_price: `490 Kč`,
    pricing_user_duration: `/ měsíc`,
    pricing_user_description: `Komunikace na úrovni`,
    pricing_user_cta: `PILOT ZDARMA`,
    pricing_user_plus_heading: `Uživatel PLUS`,
    pricing_user_plus_price: `780 Kč`,
    pricing_user_plus_duration: `/ měsíc`,
    pricing_user_plus_description: `Kompletní kooperace`,
    pricing_user_plus_cta: `Zjistit více`,
    pricing_signature_heading: `Elektronický podpis`,
    pricing_signature_price_from: `od `,
    pricing_signature_price: `9 Kč `,
    pricing_signature_duration: `/ podpis`,
    pricing_signature_description: `Smlouvy, plné moci apod.`,
    pricing_signature_cta: `Zjistit více`,
}
export default cs