import clsx from "clsx";
import React from "react";
import "./LinkButton.css";

export enum LinkButtonTypeEnum{
  Primary="Primary",
  Secondary="Secondary",
}
export type LinkButtonProps = React.PropsWithChildren<{
  uri: string;
  className?: string;
  type: LinkButtonTypeEnum;
}>;

export default function LinkButton({ className, children, uri, type }: LinkButtonProps) {
  return (
      <a href={uri} className={clsx("LinkButton", `LinkButton__${type}`,className )}>
        {children}
      </a>
  );
}
