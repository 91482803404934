const en = {
    header_home: "Home",
    header_usecase: "Use-case",
    header_usecase_link: "/use-case.html",
    header_training: "Training",
    header_training_link: "/training.html",
    header_features: "Features",
    header_features_link: "/features.html",
    header_contact: "Contact",
    header_contact_link: "/contact.html",
    header_lang: "/cs/",
    header_lang_desc: "Cs",
    header_signin: "Sign-in",
    header_signin_link: "/administration/login",
    toplook_column_heading: "We help resolve anything remotely.",
    toplook_column_body: "LiveSupp will transform your website into an interactive marketing tool, useful for visitors; we come with many other functions such as sharing content on-screen in a video call.",
    toplook_column_cta: "Free trial",
    percentagecircle1: "More conversions from phone calls and web.",
    percentagecircle2: "More effective than chat or chatbots.",
    percentagecircle3: "Increase in orders.",
    percentagecircle4: "Workflow effectiveness elevation.",
    percentagecircle5: "Faster marketing processes.",
    box_1:`Increased conversions from web, telecommunication and advertisements.`,
    box_2:`Work effectiveness increases, real-time overwatch.`,
    box_3:`Client happiness increase, building trust and positive company image.`,
    communication_heading: "Unique Marketing Tool",
    communication_subheading: "Livesupp bridges the benefits of personal communication (flexibility, trust and relationship growth) and remote access (speed, effectivity and data analytics). We stand out primarily because of the complexity of all the useful functions in one place and the simplicity of their use:",
    communication_li_1: "Voice and video calls, text chat, GDPR ready.",
    communication_li_2: "Screen and video feed sharing, collective web and document administration.",
    communication_li_3: "Electronic signature according to the eIDAS guidelines.",
    communication_li_4: "Real-time assistance with conversion completion control.",
    communication_li_5: "Interactive visualisation with the option of multimedia recording.",
    communication_cta: "All Functions",
    effectivity_heading: "Paramount Effectiveness",
    effectivity_li_1: "One-click - no installation, registration or sign in needed.",
    effectivity_li_2: "Visualisation directly onscreen during a phone call.",
    effectivity_li_3: "Useful for home office and effective time management for specialists.",
    happiness_heading: "Use-case",
    happiness_li_1: "Assisted sales, customer support and feedback gathering.",
    happiness_li_2: "Marketing campaigns - marketing transaction effectiveness rise and distinction from competition.",
    happiness_li_3: "Process support in partner channels and sales networks.",
    happiness_li_4: "Technical support with screen sharing or camera feed.",
    happiness_li_5: "Complaint management documentation with complaint protocol signature.",
    happiness_li_6: "HR - employee recruitment, real-time manageability, signature of contracts and transfer protocols.",
    happiness_cta: "Fields Of Use",
    statistics_heading: "Management Tools",
    statistics_li_1: "Immediate staff performance supervision.",
    statistics_li_2: "Case history, documentation filing and case feedback.",
    statistics_li_3: "Statistics and outcome analytics for transaction development.",
    box_4: "Simple installation; insertion of source code into Your website.",
    box_5: "We use WebRTC browser protocols with a secure peer-to-peer connection.",
    box_6: "One-click coupling on web or via sent link, no installation or registration needed.",
    references_heading: "Our Clients",
    references_1: `"We were missing an optional chat solution in our online calculators, and rather than programming the feature ourselves we decided to use LiveSupp. In addition to solving our problem LiveSupp gave us a new way of reaching our clients - through smart callback. We are satisfied with LiveSupp, it increased our business potential and also improved the quality of the services we provide for our clients."`,
    references_2: `"When comparing LiveSupp to our previous tools (chat and chatbot), the results were beyond all our expectations. Our order count immediately increased. Our website and LiveSupp became one of our primary marketing channels."`,
    pricing_heading: "Want to try out LiveSupp for free?",
    pricing_user_heading: `User`,
    pricing_user_price: `19 EUR`,
    pricing_user_duration: `/ month`,
    pricing_user_description: `Communication on a new level.`,
    pricing_user_cta: `PILOT FREE`,
    pricing_user_plus_heading: `User PLUS`,
    pricing_user_plus_price: `31 EUR`,
    pricing_user_plus_duration: `/ month`,
    pricing_user_plus_description: `Complete Cooperation.`,
    pricing_user_plus_cta: `Learn More`,
    pricing_signature_heading: `Electronic Signature`,
    pricing_signature_price_from: `from `,
    pricing_signature_price: `0.35 EUR `,
    pricing_signature_duration: `/ signature`,
    pricing_signature_description: `Contracts, procurations etc.`,
    pricing_signature_cta: `Learn More`,
}
export default en