import en from "./en"
import { FormattedMessage } from 'react-intl'
import React from "react";
import cs from "./cs";


type TranslationsType = { [key in keyof typeof en]: React.ReactNode }
const translations: TranslationsType = (Object.keys(en) as (keyof typeof en)[]).reduce((translations, key: keyof typeof en) => {
    translations[key] = <FormattedMessage
        id={key}
        defaultMessage={en[key]}
    />
    return translations
}, {} as TranslationsType)
export default translations

export const messages = { cs, en }